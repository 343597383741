<template>
  <div>
    <v-data-table :headers="headers" :items="result" :options.sync="options" :items-per-page="1000"
      class="elevation-1">
      <template v-slot:item.tb="{ item }">
        <div>{{ item.tb | formatMoney }}</div>
      </template>
      <template v-slot:item.tw="{ item }">
        <div>{{ item.tw | formatMoney }}</div>
      </template>
      <template v-slot:item.df="{ item }">
        <div v-bind:style="{color: item.tw-item.tb > 0 ? 'lime' : '#FF6D6D'}">{{ item.tw-item.tb| formatMoney }}</div>
      </template>
      <template v-slot:item.r="{ item }">
        <div v-bind:style="{color: item.r > 100 ? 'lime' : '#FF6D6D'}">{{ item.r }}%</div>
      </template>
    </v-data-table>
    <!-- <div class="text-center pt-2">
      <v-pagination v-model="currentPage" :total-visible="20" @input="initialize"
        :length="result.totalPage"></v-pagination>
    </div> -->
  </div>
</template>
<script>


export default {
  data() {
    return {
      all: [],
      headers: [
        { text: "Id", align: "left", value: "i" },
        { text: "Mode Name", value: "n" },
        { text: "Spin Count", value: "sc" },
        { text: "Total Bet", value: "tb" },
        { text: "Total Won", value: "tw" },
        { text: "Different(Total Won-Total Bet)", value: "df" },
        { text: "Current Rtp", value: "r" },
      ],
      selected: [],
      result: [],
      pagination: {
        sortBy: "id",
      },
    };
  },
  methods: {
    async initialize() {
      this.result = await this.$store.dispatch("modeRtp/getAll", {
        page: this.currentPage - 1,
        options: this.options,
      });
      this.all = this.$store.state.modeRtp.all;
      
    },
  },

  async mounted() {
    await this.initialize();  
  },
};
</script>
